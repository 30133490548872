import React from 'react'
import logo from './logo.png'
import css from './index.module.css'

const Logo = () => {
  return (
    <div className={css.logoContainer}>
      <img src={logo}/>
      <div className={css.block}></div>
      <h1>Cash-River</h1>
    </div>
    
  )
}

export default Logo
